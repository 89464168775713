import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
        <div className="container app-container">
        <div className="card">
            <div className="card-header">
                <h4 className="text-center">Download Application on clicking the linked below</h4>
            </div>
            <div className="card-content">
                <div className="col-xs-12">
                    <div className="col-xs-6 text-center">
                        <ul className="list-group">
                            <li className="list-group-item">Youtube Downloader : <a href="YoutubeDownloader/setup.exe" download="YDownloader.exe"> Download </a></li>
                            <li className="list-group-item">Check Assigned Drive Letters : <a href="CheckIfDrivesMappedCorrectly/setup.exe" download="CheckIfDrivesMappedCorrectly.exe"> Download </a></li>
                            <li className="list-group-item">Task Queue : <a href="Task_Queue/setup.exe" download="Task_Queue.exe"> Download </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default App;
